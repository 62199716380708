import { createDecorator } from 'vue-class-component'
import Vue from 'vue';




export function SyncWithRouterQuerySimple<T>(
  queryKey: string,
  { validate = e => true, defaultValue = "" as any as T, map = e => e, revMap = e => e }: {
    validate?: (e: T) => boolean,
    map?: (e: any) => T,
    revMap?: (e: T) => any,
    defaultValue?: T
  } = {}
) {
  return createDecorator((options, key) => {
    if (!options.computed)
      options.computed = {}

    options.computed[key] = {
      get() {
        try {
          var temp = map(JSON.parse((<any>this).$route.query[queryKey] || ''))
          return validate(temp as T) ? (temp || defaultValue) : defaultValue
        } catch (error) {
          return defaultValue
        }
      },
      set(value: T) {
        const { path, hash } = (<Vue>this).$route;
        (<Vue>this).$router.replace({
          path,
          hash,
          query: {
            ... (<Vue>this).$route.query,
            [queryKey]: JSON.stringify(revMap(value))
          },
        })
      }
    }
  })
}


// export function SyncWithRouterQuery<T>({ convertFrom, convertTo, validate = e => true, defaultValue }: {
//   convertFrom: (e: Dictionary<string>) => T,
//   convertTo: (e: T) => Dictionary<string>,
//   validate?: (e: T) => boolean,
//   defaultValue: T
// }) {
//   return createDecorator((options, key) => {
//     if (!options.computed)
//       options.computed = {}

//     options.computed[key] = {
//       get() {
//         try {
//           var temp = convertFrom((<Vue>this).$route.query)
//           return validate(temp) ? (temp || defaultValue) : defaultValue
//         } catch (error) {
//           return defaultValue
//         }
//       },
//       set(value: T) {
//         const { path, hash } = (<Vue>this).$route;
//         const newLocation = convertTo(value);
//         (<Vue>this).$router.replace({
//           path,
//           query: {
//             ... (<Vue>this).$route.query,
//             ...newLocation || {},
//           },
//           hash,
//         })
//       }
//     }
//   })
// }

// export function SyncWithRoute<T>({ convertFrom, convertTo, validate = e => true, defaultValue }: {
//   convertFrom: (e: Location) => T,
//   convertTo: (e: T) => Partial<Partial<Location>>,
//   validate?: (e: T) => boolean,
//   defaultValue: T
// }) {
//   return createDecorator((options, key) => {
//     if (!options.computed)
//       options.computed = {}

//     options.computed[key] = {
//       get() {
//         try {
//           var temp = convertFrom((<Vue>this).$route)
//           return validate(temp) ? (temp || defaultValue) : defaultValue
//         } catch (error) {
//           return defaultValue
//         }
//       },
//       set(value: T) {
//         const { name, path, query, params } = (<Vue>this).$route;
//         const newLocation = convertTo(value);
//         (<Vue>this).$router.replace({
//           path,
//           query: {
//             ...query,
//             ...newLocation.query || {},
//           },
//           params: {
//             ...params,
//             ...newLocation.params || {},
//           },
//           hash: newLocation.hash || undefined
//         })
//       }
//     }
//   })
// }