















import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import { accountRegister, accountRegisterResend } from '../../../apis/account.api';
import { SyncWithRouterQuerySimple } from '../../../utils/decorators/SyncWithRoute';
import { popupLoadings, errorPopupHandler, successPopupHandler } from '../../../utils/decorators/VueTimmer';

@Component
export default class RegisterConfirming extends Vue {
  
  @SyncWithRouterQuerySimple("email",{defaultValue:""})
  email: string

  @errorPopupHandler("Fail to send verification email")
  @successPopupHandler("Send verification email success")
  @popupLoadings("Sending verification email")
  onResend(){
    console.log("resend", this.email)
    return accountRegisterResend({
      email: this.email
    })
  }

}
